<script setup>
    import NavbarComponent from '@/components/NavbarComponent.vue'
    import AdminSidebarComponent from '@/components/AdminSidebarComponent.vue'
    import DataTableComponent from '@/components/DataTableComponent.vue'
    import LoaderComponent from '@/components/LoaderComponent.vue'
    import $ from 'jquery'

    import { ref, onMounted, reactive } from 'vue'
    import axios from 'axios';
    import { toast } from "vue3-toastify";
    import { useStore } from 'vuex'
    const store = useStore()
    import router from '@/router';

    var loaderShow = ref(true)
    var dashboardData = ref([])
    var showClientForm = ref(false)
    var device_types = ref([])
    var editform = ref(false)
    var changePass = ref(false)
    var showClientViewDetails = ref(false)

    const options = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": 0}},
        ordering: false,
        createdRow: function (row, data) {
          $(row).attr("id", data[0])
        },
    }

    

    var newClientForm = reactive({
      client_id: null,
      company_name: null,
      email: null,
      mobile_number: null,
      location: null,
      password: null,
      reEnteredPassword: null,
      is_active: null,
      oracle_customer_number: null,
      devices: [],
    })

    var idCount = ref(newClientForm.devices.length)

    var columns = [
      { visible: false },
      {},
      // {},
      {},
      {},
      {},
      {
        render : function ( data ) {
          if(data==true){
            return '<span class="user-active">Active</span>'
          }else{
            return '<span class="user-inactive">Inactive</span>'
          }
          
        }
      },
      {
        render : function (  ) {
          return '<button class="btn view-btn admin-action-btn admin-view-data"><i class="fa-solid fa-eye" style="color: #f2f2f2;"></i></i></button> <button  class="btn btn-primary edit-btn admin-action-btn admin-edit-data"><i class="fa-solid fa-pen-to-square" style="color: #ffffff;"></i></button> <button  class="btn btn-success client-redirect-btn admin-action-btn admin-redirect"><i class="fa-solid fa-user"></i></button>    <button class="btn btn-danger admin-action-btn delete-client"><i class="fa-solid fa-trash" style="color: #ffffff;"></i></button>'
        }
      },
    ]

    var deviceColumns = [
      { visible: false, data: 'id' },
      { data: 'device_name' },
      { data: 'device_serial' },
      { data: 'type' },
      { data: 'usage' },
      { data: 'last_reported' },
      { render : function ( data ) {
          if(data==true){
            return '<span>Yes</span>'
          }else{
            return '<span>No</span>'
          }
          
        },
        data: 'is_active' 
      },
      {
        render : function ( data ) {
          if(data==true){
            return '<span class="user-active">Active</span>'
          }else{
            return '<span class="user-inactive">Inactive</span>'
          }
          
        },
        data: 'device_status'
      },
      { data: 'created_on' },
      {
        render : function (  ) {
          return '<button class="btn btn-danger admin-action-btn delete-client"><i class="fa-solid fa-trash" style="color: #ffffff;"></i></button>'
        }
      },
    ]

    var devicesData = ref([])

    function resetForm(){
      newClientForm.client_id = null
      newClientForm.company_name = null
      newClientForm.email = null
      newClientForm.mobile_number = null
      newClientForm.location = null
      newClientForm.password = null
      newClientForm.reEnteredPassword = null
      newClientForm.is_active = null
      newClientForm.oracle_customer_number = null
      newClientForm.devices = []
      if(editform.value){
        editform.value = !editform.value
      }
    }

    async function getDeviceTypes() {

      // var clientId = store.getters.stateUser.associated_client
      if(store.getters.stateUser != null){
        axios.get('get_device_types')
        .then(response => (
        console.log("response in getDeviceTypes",response),
        device_types.value = response.data)
        )
      }else{
        router.push({ name: 'AdminLogin'})
      }
    }

    async function getClientUsersData() {

      // var clientId = store.getters.stateUser.associated_client
      if(store.getters.stateUser != null){
        axios.get('get_clients')
        .then(response => (
        console.log("response",response),
        dashboardData.value = response.data)
        )
      }else{
        router.push({ name: 'AdminLogin'})
      }
    }


    function onNewClientInput(phone, phoneObject) {
        console.log("phone",phone,phoneObject)
        if (phoneObject?.formatted) {
          newClientForm.mobile_number = phoneObject.number
        }
        if(phone == ""){
          newClientForm.mobile_number = null
        }
      }

    function addDevice(){
      idCount.value += 1
      newClientForm.devices.push({ id: `Device_${idCount.value}`, device_name: null, device_serial: null, device_type: null, is_active: false, new: true})
    }

    function removeDevice(device_id){
      console.log("delete id is ",device_id)
      for(var i=0;i<newClientForm.devices.length;i++){
        if(newClientForm.devices[i].id == device_id){
          newClientForm.devices.splice(i,1)
        }
      }
    }

    const onSubmitClient = async (event) => {
        loaderShow.value = true
        event.preventDefault()
        console.log("newform",newClientForm)

        


        try{

          if(editform.value == true){
            console.log("in here",newClientForm)
            await axios.post('edit_client_data', newClientForm)
              .then(response => (
                console.log("response in edit_client_data",response),
                loaderShow.value = false,
                toast(response.data, {
                    autoClose: 3000,
                    theme: "colored",
                    type: "success",
                    position: "bottom-left",
                    transition: "slide",
                    dangerouslyHTMLString: true
                })
              )
            )
          }else{
            await axios.post('add_new_client', newClientForm)
              .then(response => (
                console.log("response in add_new_client",response),
                loaderShow.value = false ,
                toast(response.data, {
                    autoClose: 3000,
                    theme: "colored",
                    type: "success",
                    position: "bottom-left",
                    transition: "slide",
                    dangerouslyHTMLString: true
                })
              )
            )
          }

          }catch(error){
          loaderShow.value = false
          toast(error.response.data.detail, {
              autoClose: 3000,
              theme: "colored",
              type: "error",
              position: "bottom-left",
              transition: "slide",
              dangerouslyHTMLString: true
          })
          }
        

        await getClientUsersData()
        back()

        // alert(JSON.stringify(form))
    }

    function back(){
      showClientForm.value = !showClientForm.value;
      resetForm();

      if(editform.value){
        editform.value=!editform.value
      }
      
      if(changePass.value){
        changePass.value = !changePass.value
      }
    }
    


    $(document).on('click', '.admin-edit-data', async function(){ 
      loaderShow.value = true
      var editId = $(this).parent().parent().attr("id")
    
      editform.value = !editform.value
      changePass.value = !changePass.value
      await axios.post('get_client_data', {"client_id": editId})
        .then(response => (
          newClientForm.company_name = response.data.company_name,
          newClientForm.email = response.data.email,
          newClientForm.mobile_number = response.data.mobile,
          newClientForm.location = response.data.location,
          newClientForm.is_active = response.data.is_active,
          newClientForm.oracle_customer_number = response.data.oracle_customer_number,
          newClientForm.devices = response.data.devices,
          newClientForm.client_id = editId
            
        )
      )
      showClientForm.value = !showClientForm.value
      loaderShow.value = false
      
    });

    $(document).on('click', '.admin-view-data', async function(){ 
      loaderShow.value = true
      var viewId = $(this).parent().parent().attr("id")
    
      showClientViewDetails.value = !showClientViewDetails.value
      
      await axios.post('get_client_data', {"client_id": viewId})
        .then(response => (
          newClientForm.company_name = response.data.company_name,
          newClientForm.email = response.data.email,
          newClientForm.mobile_number = response.data.mobile,
          newClientForm.location = response.data.location,
          newClientForm.is_active = response.data.is_active,
          newClientForm.oracle_customer_number = response.data.oracle_customer_number,
          devicesData.value = response.data.devices,
          newClientForm.client_id = viewId
            
        )
      )
      loaderShow.value = false
    });

    onMounted(async () => {
      await getDeviceTypes();
      await getClientUsersData();
      loaderShow.value = false
    })


</script>

<template>
<LoaderComponent :show="loaderShow" />
<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" :isAdmin="true"/>
  </BRow>
  <BRow>
    <BCol>
      <AdminSidebarComponent />

      <div class="main-expand" id="main">
        
        <DataTableComponent :data="dashboardData" :columns="columns" :options="options" v-if="!showClientForm && !showClientViewDetails">
          <template #card_heading>
              <div class="row">
                <div class="col-sm-6">
                  <h3 class="card-head-title">Manage Clients</h3>
                </div>
                
                <div class="col-sm-6">
                  <div class="d-flex justify-content-end">
                    <BButton variant="primary" @click="showClientForm = !showClientForm" class="md add-user-btn" ><font-awesome-icon :icon="['fas', 'user-plus']" /> &nbsp;Add New Client</BButton>
                  </div>
                </div>
              </div>
                
                
            </template>
            <template #table_header>
                <th>id</th>
                <th>Oracle Customer Number</th>
                <!-- <th>Serial Number</th> -->
                <th>Company Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Status</th>
                <th>Actions</th>
            </template>
        </DataTableComponent>


        <div class="card settings-card"  v-if="showClientForm">
            <div class="form-wrapper">
              <BForm @submit="onSubmitClient" @reset="resetForm">
                <div class="row">
                  <div class="col-sm-6">
                    <h3 v-if="editform" class="card-head-title">Edit Client</h3>
                    <h3 v-else class="card-head-title">Add New Client</h3>
                  </div>
                  
                  <div class="col-sm-6">
                    <div class="d-flex justify-content-end">
                      <BButton variant="primary" class="md"  @click="back()">Go Back</BButton>
                    </div>
                  </div>
                </div>

                <div class="row form-row">              

                      <div class="col-lg-6 indv-input">
                          <BFormGroup id="input-group-1" label="Email Address" label-for="Email Address" >
                            <BFormInput v-model="newClientForm.email" :disabled="editform" placeholder="Enter your email"  type="email" size="md"  required />
                          </BFormGroup>
                          
                      </div>
                      <div class="col-lg-6 indv-input">
                          <BFormGroup id="input-group-1" label="Company Name" label-for="Company Name" >
                              <BFormInput v-model="newClientForm.company_name" placeholder="Enter your Company Name"   type="text" size="md"  required />
                          </BFormGroup>


                      </div>
                      
                </div>

                <div class="row form-row">              

                  <div class="col-lg-6 indv-input">
                      <BFormGroup id="input-group-1" label="Mobile" label-for="name" >
                          <vue-tel-input class="form-control form-control-sm" :value="newClientForm.mobile_number" @input="onNewClientInput" mode="international" :required="true"></vue-tel-input>
                      </BFormGroup>
                      
                  </div>

                  <div class="col-lg-6 indv-input">
                      <BFormGroup id="input-group-1" label="Customer Location" label-for="name" >
                      <BFormInput v-model="newClientForm.location" placeholder="Enter your location"   type="text" size="md"  required />
                      </BFormGroup>
                      
                  </div>

                  

                </div>


                  <div class="row form-row" >              

                      <div class="col-lg-6 indv-input" v-if="!changePass">
                          <BFormGroup id="input-group-1" label="Password" label-for="name" >
                          <BFormInput v-model="newClientForm.password" placeholder="Enter password"   type="password" size="md"  required />
                          </BFormGroup>
                          
                      </div>
                      <div class="col-lg-6 indv-input" v-if="!changePass">
                          <BFormGroup id="input-group-1" label="Re-enter Password" label-for="" >
                          <BFormInput v-model="newClientForm.reEnteredPassword" placeholder="Re-enter password"  type="password" size="md" required />
                          </BFormGroup>
                      </div>

                      <div class="d-flex justify-content-end" v-if="changePass"><BButton class="btn form-btn" @click="changePass = !changePass">Change Password</BButton></div>
                  </div>

                <div class="row form-row">
                  <div class="col-lg-6 indv-input">
                      <BFormGroup id="input-group-1" label="Oracle Customer Number" label-for="" >
                      <BFormInput v-model="newClientForm.oracle_customer_number" placeholder="Enter Oracle Customer Number"  type="text" size="md" required />
                      </BFormGroup>
                  </div>

                  <div class="col-lg-6 indv-input">
                    <BFormGroup id="input-group-status" label="Status:" label-for="" >
                      <BFormRadioGroup id="radio-group-status" v-model="newClientForm.is_active" name="radio-client-sub-component" :required="true">
                        <BFormRadio :value=true>Active</BFormRadio>
                        <BFormRadio :value=false>Inactive</BFormRadio>
                      </BFormRadioGroup>
                    </BFormGroup>
                  </div>
                </div>


                <div class="row">
                  <div class="d-flex justify-content-start"> <BButton class="form-submit-btn" @click="addDevice" variant="primary">Add Device</BButton></div>
                </div>

                

                <div class="row form-row mt-3" v-for="device in newClientForm.devices" :key="device.id">        
                     
                  <hr>
                  

                  <div class="col-lg-4 indv-input">
                      <BFormGroup id="input-group-1" label="Device Name" label-for="device_name" >
                      <BFormInput v-model="device.device_name" placeholder="Enter Device Name"   type="text" size="md"  required />
                      </BFormGroup>
                      
                  </div>
                  <div class="col-lg-4 indv-input">
                      <BFormGroup id="input-group-1" label="Device Serial" label-for="device_serial" >
                      <BFormInput v-model="device.device_serial" placeholder="Enter Device Serial"  type="text" size="md" required />
                      </BFormGroup>
                  </div>

                  <div class="col-lg-4 indv-input">
                      <BFormGroup id="input-group-1" label="Device Type" label-for="device_type" >
                          <select v-model="device.device_type" class="form-select form-select-md" aria-label="length-select" required>
                              <option selected value='null'>Please Select</option>
                              <option v-for="dt in device_types" :key="dt.dt_id" :value="dt.dt_id">{{ dt.name }}</option>
                          </select>
                      </BFormGroup>
                  </div>

                  <div class="col-lg-4 indv-input">
                    <BFormGroup label="Active Status on Dashboard:">
                      <BFormRadioGroup v-model="device.is_active" :name="device.device_serial" :required="true">
                        <BFormRadio :value=true >Yes</BFormRadio>
                        <BFormRadio :value=false>No</BFormRadio>
                      </BFormRadioGroup>
                    </BFormGroup>
                  </div>

                  <div class="row">
                    <div class="d-flex justify-content-start"> <BButton class="" @click="removeDevice(device.id)" variant="danger">Remove</BButton></div>
                  </div>

                  <br/>

                </div>

                <hr>

                <div class="row">
                  <div v-if="editform" class="d-flex justify-content-end"> <BButton class="form-submit-btn" type="submit" variant="primary">Update Client</BButton></div>
                  <div v-else class="d-flex justify-content-end"> <BButton class="form-submit-btn" type="submit" variant="primary">Add Client</BButton></div>
                </div>

              </BForm>
            </div>
        </div>


        <div class="card settings-card"  v-if="showClientViewDetails">
          <div class="form-wrapper">
            <div class="row">
                <div class="col-sm-6">
                  <h3 class="card-head-title">Client Profile Information</h3>
                </div>
                
                <div class="col-sm-6">
                  <div class="d-flex justify-content-end">
                    <BButton variant="primary" class="md"  @click="showClientViewDetails = !showClientViewDetails;resetForm()">Go Back</BButton>
                  </div>
                </div>
            </div>

            <div class="row form-row">
              <div class="col-sm-6">Oracle Customer Number: {{ newClientForm.oracle_customer_number }}</div>
              <div class="col-sm-6">Company Name: {{ newClientForm.company_name }}</div>
            </div>
            
            <div class="row form-row">
              <div class="col-sm-6">Email Address: {{ newClientForm.email }}</div>
              <div class="col-sm-6">Mobile Number: {{ newClientForm.mobile_number }}</div>
            </div> 

            <div class="row form-row">
              <div class="col-sm-6">Location: {{ newClientForm.location }}</div>
              <div class="col-sm-6">Email Verified: No</div>
            </div> 

            <div class="row form-row">
              <div v-if="newClientForm.is_active" class="col-sm-6">Status: Active</div>
              <div v-else class="col-sm-6">Status: Inactive</div>
            </div> 

          </div>

          <DataTableComponent :data="devicesData" :columns="deviceColumns" :options="options">
            <template #card_heading>
                <div class="row">
                  <div class="col-sm-6">
                    <h3 class="card-head-title">Devices</h3>
                  </div>

                </div>
                  
                  
              </template>
              <template #table_header>
                  <th>id</th>
                  <th>Name</th>
                  <th>Serial Number</th>
                  <th>Type</th>
                  <th>Usage</th>
                  <th>Last Reported</th>
                  <th>Active</th>
                  <th>Status</th>
                  <th>Created On</th>
                  <th>Action</th>
              </template>
          </DataTableComponent>
        </div>


      </div>

      
    </BCol>
  </BRow>
  
</BContainer>
</template>
