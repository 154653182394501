<script setup>

  import NavbarComponent from '../components/NavbarComponent.vue'
  import SidebarComponent from '../components/SidebarComponent.vue'
  import DataTableComponent from '../components/DataTableComponent.vue'
  
  import router from '@/router';
  import { ref,onMounted } from 'vue'
  import axios from 'axios';
  import { useStore } from 'vuex'
  const store = useStore()

  

    const options = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": -1}},
        ordering: false,
    }

    var eventData = ref([])

    const columns = [
      {},
      {},
      {},
      { render : function ( data ) {
        data = data.split(",")
        return data.length > 1 ? '<span class="adj-data-highlight"><span class="dataTable-span-highlight">Drive</span> <span class="adjustment-numbers">' + data[0] +  '</span></span> | <span class="adj-data-highlight"><span class="dataTable-span-highlight">Operator</span> <span class="adjustment-numbers">' + data[1] +  '</span></span>' : data
      }},
    ]

    async function getEventData() {

      
      if(store.getters.stateUser != null){
        axios.post('get_event_data', store.getters.stateUser)
          .then(response => (
          console.log("response in events",response),
          eventData.value = response.data)
        )
      }
      else{
        router.push({ name: 'Home'})
      }
      
    }

    onMounted(() => {
      getEventData();
    })

    // const eventData=[
    //     ['Max',	'11/17/2023 09:20:18', 'Job Start',	'TEST3.0-70'],
    //     ['Max',	'11/17/2023 09:12:08',	'Job End',	'TEST3.070'],
    //     ['Max',	'11/20/2023 14:57:36',	'Job End',	'TESTEUKF'],
    //     ['Max',	'11/17/2023 09:08:33',	'Job Start',	'TEST3.070'],
    //     ['Max',	'11/17/2023 09:08:01',	'Adjustments',	'-0.5,1.5'],
    //     ['Max',	'11/17/2023 09:08:01',	'Adjustments',	'-0.5,1.5'],
    //     ['Max',	'11/17/2023 09:08:01',	'Adjustments',	'-0.5,1.5'],
    //     ['Max',	'11/20/2023 14:57:36',	'Job End',	'TESTEUKF'],
        
    // ]
</script>

<template>

<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" />
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        <DataTableComponent :data="eventData" :options="options" :columns="columns">
          <template #card_heading>
            <h3 class="card-head-title">Events</h3>
          </template>
          <template #table_header>
            <th>Device Name</th>
            <th>Reported On</th>
            <th>Event Type</th>
            <th>Event Data</th>
          </template>
        </DataTableComponent>
      </div>
    </BCol>
  </BRow>
  
</BContainer>
</template>

<script>
    
    

</script>