<script setup>
    import { ref, reactive, computed } from 'vue';
    import axios from 'axios';
    import { useStore } from 'vuex'
    const store = useStore()
    import router from '@/router'
    import $ from 'jquery'

    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'
    import LoaderComponent from '../components/LoaderComponent.vue'

    var loaderShow = ref(false)

    var form = reactive({
        report_type: null,
        start_date: null,
        end_date: null,
        selected_device_type: "ALL",
        selected_device: "ALL",
        selected_job: "ALL",
        selected_maint_type: 'ALL',
        all_events: false,
        priority_events: [],
        specific_events: [],
        client_id: store.getters.stateUser != null ? store.getters.stateUser.associated_client : null,
        user_id: store.getters.stateUser != null ? store.getters.stateUser.id : null,
    })

    var paramters = reactive({})

    // import $ from 'jquery'
    var events = ref(false)
    // var device_status = ref(false)
    var job_status = ref(false)
    var maintenance = ref(false)
    var job_history = ref(false)


    function selectAllEvents(){
        if($('#all-chkbox').is(":checked")){
            $(".event-chkbox").prop('checked', true);
        }else{
            $(".event-chkbox").prop('checked', false);
        }
    }
    
    function selectAllCritical(){
        if($('#critical-chkbox').is(":checked")){
            $(".critical-chkbox").prop('checked', true);
        }else{
            $(".critical-chkbox").prop('checked', false);
        }
    }

    function selectAllModerate(){
        if($('#moderate-chkbox').is(":checked")){
            $(".moderate-chkbox").prop('checked', true);
        }else{
            $(".moderate-chkbox").prop('checked', false);
        }
    }

    function selectAllMinor(){
        if($('#minor-chkbox').is(":checked")){
            $(".minor-chkbox").prop('checked', true);
        }else{
            $(".minor-chkbox").prop('checked', false);
        }
    }
    

    function onReportChange(event){
        events.value = false;
        maintenance.value = false;
        job_history.value = false;
        job_status.value = false;
        var reportTypeValue = event.target.value
        if( reportTypeValue == "events_report"){
            events.value = true;
        }
        if( reportTypeValue == "job_status_report"){
            job_status.value = true
        }
        if( reportTypeValue == "upcoming_maintenance_report"){
            maintenance.value = true
        }
        if( reportTypeValue == "job_history_report"){
            job_history.value = true
        }
    }

    async function getReportParams(){
      if(store.getters.stateUser != null){
        await axios.post('get_report_params', store.getters.stateUser)
          .then(response => (
              console.log("response in get_report_params",response),
              paramters = response.data
          )
        )
      }
      else{
        router.push({ name: 'Home'})
      }
    }

    async function getSummaryReport(){
        console.log("forms in submit",form)
        var output = null
        if(store.getters.stateUser != null){
            // await axios.post('get_summary_report', store.getters.stateUser,{responseType: 'blob'})
            await axios.post('get_summary_report', store.getters.stateUser)
            .then(response => (
                console.log("response in get_summary_report"),
                output = response
            )
            )
            return output
        }
        else{
            router.push({ name: 'Home'})
        }
    }



    async function downloadSummaryReport(){
        loaderShow.value = true
        var response = await getSummaryReport()
        // const contentDisposition = response.headers['content-disposition']
        // let fileName = 'unknown'
        // if (contentDisposition) {
        //     const fileNameMatch =  contentDisposition.match(/filename="(.+)"/)
        //     if(fileNameMatch.length === 2)
        //         fileName = fileNameMatch[1]
        // }
        // const url = URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.ms-excel'}))
        const link = document.createElement('a')
        // link.href = url
        link.href = response.data
        // link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        loaderShow.value = false
    }

    async function getReportFile(){
        console.log("forms in submit",form)
        var output = null
        if(store.getters.stateUser != null){
            // await axios.post('get_report', form, {responseType: 'blob'})
            await axios.post('get_report', form)
            .then(response => (
                console.log("response in get_report",response),
                output = response
            )
            )
            return output
        }
        else{
            router.push({ name: 'Home'})
        }
    }

    async function downloadReport(){
        loaderShow.value = true
        var response = await getReportFile()
        // const contentDisposition = response.headers['content-disposition']
        // let fileName = 'unknown'
        // if (contentDisposition) {
        //     const fileNameMatch =  contentDisposition.match(/filename="(.+)"/)
        //     if(fileNameMatch.length === 2)
        //         fileName = fileNameMatch[1]
        // }
        // const url = URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.ms-excel'}))
        const link = document.createElement('a')
        // link.href = url
        link.href = response.data
        // link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()

        form.selected_device_type =  "ALL"
        form.selected_device =  "ALL"
        form.selected_job =  "ALL"
        form.selected_maint_type =  'ALL'
        form.all_events = false
        form.priority_events = []
        form.specific_events = []
        loaderShow.value = false
    }

    const filtered_mt_types = computed(() => {
       var filtered = []
       for(var i=0; i< paramters.maintenance_types.length; i++){
         if(form.selected_device_type == paramters.maintenance_types[i].associated_device_type){
            filtered.push(paramters.maintenance_types[i])
         }
       }

       return filtered
    })

    getReportParams()
   
</script>

<template>
<LoaderComponent :show="loaderShow" />
<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" />
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        <div class="card settings-card">
            <div class="form-wrapper">

                <div class="text-center">
                    <div class="row">
                        <h3>Download Reports</h3>
                        <div class="d-flex justify-content-end">
                            <BButton @click="downloadSummaryReport" class="form-submit-btn" type="submit" variant="primary"><font-awesome-icon :icon="['fas', 'circle-arrow-down']" /> &nbsp; Summary Report</BButton>
                        </div>
                        
                    </div>
                </div>
                
                

                <div class="row mb-3">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="Report Type" label-for="input-1" >
                            <select @change="onReportChange($event)" v-model="form.report_type" id="reportType" class="form-select form-select-sm" aria-label="length-select">
                                <option :selected="form.report_type == null" value="null">Please Select</option>
                                <option value="events_report">Events Report</option>
                                <option value="flex_die_report">Flex Die Report</option>
                                <option value="solid_die_report">Solid Die Report</option>
                                <option value="mag_cyl_report">Mag Cyl. Report</option>
                                <option value="job_status_report">Job status Report</option>
                                <option value="upcoming_maintenance_report">Upcoming Maintenance Report</option>
                                <option value="completed_maintenance_report">Completed Maintenance Report</option>
                                <option value="job_history_report">Job History Report</option>
                            </select>
                        </BFormGroup>
                    </div>
                    <div class="col-lg-3"></div>
                </div>

                
                
                <div class="row">
                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="Start Date" label-for="input-1" >
                            <BFormInput id="" type="date" v-model="form.start_date" :value="form.start_date"/>
                        </BFormGroup>
                    </div>
                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="End Date" label-for="input-1" >
                            <BFormInput id="" type="date" v-model="form.end_date" :value="form.end_date"/>
                        </BFormGroup>
                    </div>
                </div>

                <hr>

                <div class="row" v-if="events">
                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="Event Type" label-for="input-1" >
                            <BAccordion class="event-select">
                                <BAccordionItem title="Please Select">
                                    <div class="">
                                        
                                        <BListGroup>
                                            <BListGroupItem><BFormCheckbox id="all-chkbox" v-model="form.all_events" @input="selectAllEvents()">ALL</BFormCheckbox></BListGroupItem>
                                            <div  v-for="priority in Object.keys(paramters.events)" :key="priority" :class="[priority == 'High' ? 'critical-group' : priority == 'Moderate' ? 'moderate-group' : 'minor-group']">
                                                <BListGroupItem>
                                                    <BFormCheckbox v-model="form.priority_events" v-if="priority == 'High'" @input="selectAllCritical()" :value="priority" class="event-chkbox" id="critical-chkbox">{{ priority }}</BFormCheckbox>
                                                    <BFormCheckbox v-model="form.priority_events" v-if="priority == 'Moderate'" @input="selectAllModerate()" :value="priority" class="event-chkbox" id="moderate-chkbox">{{ priority }}</BFormCheckbox>
                                                    <BFormCheckbox v-model="form.priority_events" v-if="priority == 'Low'" @input="selectAllMinor()" :value="priority" class="event-chkbox" id="minor-chkbox">{{ priority }}</BFormCheckbox>
                                                </BListGroupItem>
                                                <div class="event-list-group">
                                                    <BListGroupItem v-for="evt in paramters.events[priority]" :key="evt.id" >
                                                        <BFormCheckbox v-model="form.specific_events" :value="evt.id" class="event-chkbox" :class="[ priority == 'High' ? 'critical-chkbox' : priority == 'Moderate' ? 'moderate-chkbox' : 'minor-chkbox']">{{ evt.event_name }}</BFormCheckbox>
                                                    </BListGroupItem>
                                                    <!-- <BListGroupItem><BFormCheckbox class="event-chkbox critical-chkbox" >Under Pressure</BFormCheckbox></BListGroupItem>
                                                    <BListGroupItem><BFormCheckbox class="event-chkbox critical-chkbox" >Anvil Pressure Change</BFormCheckbox></BListGroupItem>
                                                    <BListGroupItem><BFormCheckbox class="event-chkbox critical-chkbox" >Adjustments at Run Speed</BFormCheckbox></BListGroupItem> -->
                                                </div>
                                            </div>

                                            <!-- <div class="moderate-group">
                                                <BListGroupItem><BFormCheckbox @input="selectAllModerate()" class="event-chkbox" id="moderate-chkbox">Moderate Critical</BFormCheckbox></BListGroupItem>
                                                <div class="event-list-group">
                                                    <BListGroupItem><BFormCheckbox class="event-chkbox moderate-chkbox" >Over Pressure</BFormCheckbox></BListGroupItem>
                                                    <BListGroupItem><BFormCheckbox class="event-chkbox moderate-chkbox" >Under Pressure</BFormCheckbox></BListGroupItem>
                                                    <BListGroupItem><BFormCheckbox class="event-chkbox moderate-chkbox" >Anvil Pressure Change</BFormCheckbox></BListGroupItem>
                                                    <BListGroupItem><BFormCheckbox class="event-chkbox moderate-chkbox" >Adjustments at Run Speed</BFormCheckbox></BListGroupItem>
                                                </div>
                                            </div>

                                            <div class="minor-group">
                                                <BListGroupItem><BFormCheckbox @input="selectAllMinor()" class="event-chkbox" id="minor-chkbox">Minor Critical</BFormCheckbox></BListGroupItem>
                                                <div class="event-list-group">
                                                    <BListGroupItem><BFormCheckbox class="event-chkbox minor-chkbox" >Over Pressure</BFormCheckbox></BListGroupItem>
                                                    <BListGroupItem><BFormCheckbox class="event-chkbox minor-chkbox" >Under Pressure</BFormCheckbox></BListGroupItem>
                                                    <BListGroupItem><BFormCheckbox class="event-chkbox minor-chkbox" >Anvil Pressure Change</BFormCheckbox></BListGroupItem>
                                                    <BListGroupItem><BFormCheckbox class="event-chkbox minor-chkbox" >Adjustments at Run Speed</BFormCheckbox></BListGroupItem>
                                                </div>
                                            </div> -->
                                        </BListGroup>
                                    </div>
                                    
                                </BAccordionItem>
                                
                            </BAccordion>
                                
                        </BFormGroup>
                    </div>
                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="Device Name" label-for="input-1" >
                            <select id="reportType" v-model="form.selected_device" class="form-select form-select-sm" aria-label="length-select">
                                <option :selected="form.selected_device == 'ALL'" value="ALL">ALL</option>
                                <option  v-for="device in paramters.devices" :key="device.device_id" :value="device.device_id">{{ device.device_name }}</option>
                            </select>
                        </BFormGroup>
                    </div>
                </div>


                <!-- <div class="row" v-if="device_status">
                    <h5>Flex Die</h5>
                    <div class="col-lg-6 mb-3">
                        <BFormGroup id="input-group-1" label="Start Date" label-for="input-1" >
                            <BFormInput id="" type="date" />
                        </BFormGroup>
                    </div>
                    <div class="col-lg-6 mb-3">
                        <BFormGroup id="input-group-1" label="End Date" label-for="input-1" >
                            <BFormInput id="" type="date" />
                        </BFormGroup>
                    </div>

                    <hr>

                    <h5>Solid Die</h5>
                    <div class="col-lg-6 mb-3">
                        <BFormGroup id="input-group-1" label="Start Date" label-for="input-1" >
                            <BFormInput id="" type="date" />
                        </BFormGroup>
                    </div>
                    <div class="col-lg-6 mb-3">
                        <BFormGroup id="input-group-1" label="End Date" label-for="input-1" >
                            <BFormInput id="" type="date" />
                        </BFormGroup>
                    </div>

                    <hr>

                    <h5>Mag Cyl.</h5>
                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="Start Date" label-for="input-1" >
                            <BFormInput id="" type="date" />
                        </BFormGroup>
                    </div>
                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="End Date" label-for="input-1" >
                            <BFormInput id="" type="date" />
                        </BFormGroup>
                    </div>
                </div> -->


                <div class="row" v-if="job_status">
                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="Device Name" label-for="input-1" >
                            <select id="reportType" v-model="form.selected_device" class="form-select form-select-sm" aria-label="length-select">
                                <option :selected="form.selected_device == 'ALL'" value="ALL">ALL</option>
                                <option  v-for="device in paramters.devices" :key="device.device_id" :value="device.device_id">{{ device.device_name }}</option>
                            </select>
                        </BFormGroup>
                    </div>
                </div>

                <div class="row"  v-if="maintenance">
                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="Maintenance Type" label-for="input-1" >
                            <select id="reportType" v-model="form.selected_maint_type" class="form-select form-select-sm" aria-label="length-select">
                                <option :selected="form.selected_maint_type == 'ALL'" value="ALL">ALL</option>
                                <option v-for="mt in filtered_mt_types" :key="mt.mt_id" :value="mt.mt_id">{{ mt.name }}</option>
                            </select>
                        </BFormGroup>
                    </div>

                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="Device Type" label-for="input-1" >
                            <select id="reportType" v-model="form.selected_device_type" class="form-select form-select-sm" aria-label="length-select">
                                <option :selected="form.selected_device_type == 'ALL'" value="ALL">ALL</option>
                                <option v-for="dt in paramters.device_types" :key="dt.dt_id" :value="dt.dt_id">{{ dt.name }}</option>
                                <!-- <option value="Flex Die Report">Solid Die</option>
                                <option value="Flex Die Report">Mag Cyl.</option>
                                <option value="Flex Die Report">Others</option> -->
                            </select>
                        </BFormGroup>
                    </div>
                </div>

                <div class="row" v-if="job_history">
                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="Job Name" label-for="input-1" >
                            <select id="reportType" v-model="form.selected_job" class="form-select form-select-sm" aria-label="length-select">
                                <option :selected="form.selected_job == 'ALL'" value="ALL">ALL</option>
                                <option v-for="job in paramters.jobs" :key="job.job_id" :value="job.job_id">{{ job.job_name }}</option>
                                
                            </select>
                        </BFormGroup>
                    </div>

                    <div class="col-lg-6">
                        <BFormGroup id="input-group-1" label="Device Name" label-for="input-1" >
                            <select id="reportType" v-model="form.selected_device" class="form-select form-select-sm" aria-label="length-select">
                                <option :selected="form.selected_device == 'ALL'" value="ALL">ALL</option>
                                <option  v-for="device in paramters.devices" :key="device.device_id" :value="device.device_id">{{ device.device_name }}</option>
                            </select>
                        </BFormGroup>
                    </div>
                </div>

                <div class="row text-center mt-3">
                    <div class="sumbit-btn-wrapper"><BButton @click="downloadReport()" class="form-submit-btn" type="submit" variant="primary">CREATE</BButton></div>
                    
                </div>



            </div>
        </div>
      </div>
    </BCol>
  </BRow>
  
</BContainer>
</template>

