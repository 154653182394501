<script setup>
  import { ref } from 'vue'
  import NavbarComponent from '../components/NavbarComponent.vue'
  import LoaderComponent from '../components/LoaderComponent.vue'
  // import { mapActions } from 'vuex';
  import router from '@/router';
  import { useStore } from 'vuex'
  import { toast } from "vue3-toastify";
  
  
  const store = useStore()
  const emailAddress = ref('')
  const passWord = ref('')
  var loaderShow = ref(false)
  

  // ...mapActions(['logIn']),
  async function submit(event) {
    loaderShow.value = true
    event.preventDefault()
    const User = new FormData();
    User.append('username', emailAddress.value);
    User.append('password', passWord.value);
    var repsonseData = await store.dispatch('logIn',User);

    if(repsonseData.status == 200){
      loaderShow.value = false
      router.push({ name: 'Dashboard' });
    }else{
      loaderShow.value = false
      toast(repsonseData.data.detail, {
        autoClose: 3000,
        theme: "colored",
        type: "error",
        position: "bottom-left",
        transition: "slide",
        dangerouslyHTMLString: true
      }); // ToastOptions
    }
    
  }

  


</script>




<template>
  
    <NavbarComponent :sideBarEnable="false"/>
    <LoaderComponent :show="loaderShow" />
    <BContainer class="vh-100 d-flex justify-content-center align-items-center" >
    <BRow >
      
      <BCol>
        
        <div class="login-container text-center">
          <BForm @submit="submit">
          <div class="login-container-header p-3">
            <h4 class="mt-2 ps-5 pe-5"><div>Welcome back,</div>
              <span class="">Please sign in to your account below.</span>  
            </h4>  
          </div>
          <div class="login-container-body p-3">
            <BFormInput class="form-inputs" name="username" type="email" required  v-model="emailAddress" placeholder="Email Address" />
            <BFormInput class="form-inputs mt-3" name="password" type="password" required  v-model="passWord" placeholder="Password" />
          </div>
          <div class="login-container-footer w-100 d-flex justify-content-end p-3">
            <BButton   class="btn btn-lg btn-link recover-pass-btn">Recover Password</BButton >
            <BButton type="submit" class="login-submit-btn btn">Submit</BButton >
          </div>
        </BForm>
        </div>
        <div  class="text-center text-black opacity-50 mt-3">Copyright © Maxcess International Inc. 2023</div>
      </BCol>
    </BRow>
    </BContainer>
</template>

